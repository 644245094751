<template>
  <div class="custom-control custom-switch">
    <input
      v-bind="$attrs"
      type="checkbox"
      class="custom-control-input"
      v-bind:disabled="disabled"
      v-bind:id="componentId + '-value'"
      ref="switch"
      v-bind:checked="value"
      v-on:input="setSwitchStatus"
      v-on:change="onChange"
    />
    <label
      v-bind:class="['custom-control-label no-select', labelClass]"
      v-bind:for="componentId + '-value'"
      ref="label"
      v-html="getLabel"
    >
    </label>
  </div>
</template>
<script>
export default {
  name: "WeSwitch",
  props: {
    value: {
      default: () => false,
    },
    label: {
      default: "",
    },
    showStatus: {
      default: false,
    },
    disabled: {
      default: false,
    },
    labelClass: {
      default: "",
    },
  },
  computed: {
    componentId() {
      return "we-switch-" + helper.getRandomInteger(1, 999999);
    },
    getLabel() {
      if (!this.label) {
        if (this.showStatus) {
          return this.value ? this.$t("active") : this.$t("passive");
        }
      } else {
        return this.label;
      }
    },
  },
  methods: {
    setSwitchStatus(e) {
      const value = e && e.target ? e.target.checked : e;
      this.$refs.switch.checked = value;
      this.$emit("input", value);
    },
    onChange(e) {
      const value = e && e.target ? e.target.checked : e;
      this.$emit("change", value);
    },
  },
  mounted() {
    this.setSwitchStatus(this.value);
  },
};
</script>
